import axios from 'axios';
import {UserModule} from '@/store/User';
import {apiGetZlacToken, getLogInURL} from './zlacAPI';
import {log} from '@/utilities/logger'
import {calculateGrade} from '@/utilities/article_analyzer'
import { LoginAPI } from './loginAPI';
import {LISTEN_READ_LEVEL} from '@/utilities/parameters'
import { isCompleteSentence } from '@/utilities/article_analyzer';

const getApiURL = (url: string) => {
  if (url.includes("alls-dev.asus.com")) {
    return "https://alls-dev.southeastasia.cloudapp.azure.com/api/";
  } else if (url.includes("alls-stage.asus.com")) {
    return "https://alls-stage.southeastasia.cloudapp.azure.com/api/";

  } else if (url.includes("alls.asus.com") || url.includes("alls.southeastasia.cloudapp.azure.com") || url.includes("52.187.144.188")) {
    return "https://alls.southeastasia.cloudapp.azure.com/api/";
  }
  return "https://alls-stage.southeastasia.cloudapp.azure.com/api/";
};

const getApi2URL = (url: string) => {
  if (url.includes("alls-dev.asus.com")) {
    return "https://alls-stage.southeastasia.cloudapp.azure.com/api2/history/";
  } else if (url.includes("alls-stage.asus.com")) {
    return "https://alls-stage.southeastasia.cloudapp.azure.com/api2/history/";
    // return "http://localhost:6600/history/"
  } else if (url.includes("alls.asus.com") || url.includes("alls.southeastasia.cloudapp.azure.com") || url.includes("52.187.144.188")) {
    return "https://alls.southeastasia.cloudapp.azure.com/api2/history/";
  }
  return "https://alls-stage.southeastasia.cloudapp.azure.com/api2/history/";
};
const instance = axios.create({baseURL: getApiURL(window.location.hostname)});
const instanceApi2 = axios.create({baseURL: getApi2URL(window.location.hostname)});

instance.interceptors.request.use(
  config => {
    if(config.headers !== undefined) {
      config.headers['Authorization'] = UserModule.zlacToken;
      return config;
    }
  },
  error => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    // log("<Error>",
    //   "\nstatus:", error.response.status,
    //   "\nurl:", error.response.config.baseURL, error.response.config.url,
    //   "\ndata:", error.response.config.data,
    //   "\nmessage:", error.response.data);
    if (error.config.__retryCount === undefined) {
      error.config.__retryCount = 0;
    }
    let status = -1;
    if (error.response) {
      if (error.response.status) {
        status = error.response.status;
      }
    }
    if (status !== 401) {
      return error.response;
    }
    if (error.config.__retryCount < 3 && status === 401) {
      error.config.__retryCount = error.config.__retryCount + 1;
      return new Promise(async (resolve, reject) => {
        const response = await apiGetZlacToken();
        UserModule.setZlacToken(response.data.Token);
        resolve(instance(error.config));
      });
    }
    if (error.config.__retryCount == 3) {
      LoginAPI.cleanCookie();
      window.location.href = getLogInURL(window.location.hostname)
      return;
    }
    return Promise.reject(error);
  }
);

export const apiGetCourses = () => {
  return instance.get('/courses');
};

export const apiGetCoursesDemo = () => {
  return instance.get('/courses/units');
};

export const conversationGPT=async (personA:string, personB:string, level:string, topic:string, sentences:string)=>{
  var sysSetupWord="respond in English and generate "+sentences+" sentences about "+topic+". The number of words is limited to between 1 and 10. Each sentence should have a line break(\n) and no bullet numbers."

  var sysSetup = "You are " + personA + ". You are engaged in a conversation with " + personB 
  + ". Respond in English using vocabulary appropriate for level " + level 
  + ". Avoid formalities and refrain from repeating their questions. Craft your responses based on the given scenario: " + topic
  + " Prior to each sentence, include the relevant character's prompt. Characters are restricted to the provided names. Maintain smooth grammar and coherent dialogue."
  + " Avoid using unusual or irrelevant symbols. "
  + " Generate only " + sentences + " sentences for the conversation, each limited to one sentence." 
  + " Follow the dialogue format demonstrated in this example: \""+personA+": I love eating food.\""
  + " Generated sentences must not be empty. Please give me a simple conversation example without any explanation. There must be a sentence starting with Znebo at the beginning of the first sentence. There is no need to provide any explanation at the beginning, and there is no need to label Example:.";
  
  let goPrompt=[{role:'system',content:personA&&personB?sysSetup:sysSetupWord}]
  return await apiGenGhatGPT(goPrompt)
}

export const Optimization_GPT=async (topic:String, level:String, words:Number, story: String)=>{
  let keyWordList_CEFR_PreA  = "a(an), apple, arm, at, "
                +"bag, be(am, are, is), ball, "
                +"bird, blue, book, box, boy, "
                +"banana, bear, big, black, "
                +"bookstore, brother, "
                +"cake, cat, color, cow, cup, "
                +"can, chair, cloudy, cold, "
                +"cook, cool, cry, "
                +"dad, dog, dance, day, desk, "
                +"do(does), doctor, door, draw, "
                +"egg, ear, eight, eleven, "
                +"eraser, eye, "
                +"fish, face, fan, father, five, "
                +"foot, four, Friday, friend, "
                +"girl, green, grandma, grandpa, "
                +"hamburger, hand, he, head, "
                +"home, hot, how, "
                +"I, it, ice cream, in, "
                +"juice, jump, "
                +"kid, key, kite, "
                +"leg, like, lion, long, "
                +"mom, my, many, marker, "
                +"milk, Monday, monkey, "
                +"mother, mouth, much, "
                +"name, nine, no, nose, now, not, "
                +"o'clock, old, on, one, orange, "
                +"pencil, pig, park, pen, pizza, "
                +"red, rabbit, rainy, read, "
                +"rice, ruler, "
                +"Saturday, school, seven, "
                +"she, short, sing, sister, six, "
                +"sleep, small, student, "
                +"Sunday, sunny, swim, "
                +"that, this, table, tall, tea, "
                +"teacher, ten, the, these, "
                +"they, thin, those, three, "
                +"Thursday, tiger, time, "
                +"today, Tuesday, twelve, two, "
                +"under, "
                +"what, want, warm, water, "
                +"we, weather, Wednesday, "
                +"week, where, white, who, "
                +"window, windy, write, "
                +"yellow, you, yo-yo, year, yes, "
                +"zoo\n"
  let keyWordList_CEFR_A1 = "a(an), apple, arm, at, and, angry, art, aunt, "
                +"bag, be(am, are, is), ball, bird, blue, book, "
                +"box, boy, banana, bear, big, black, bookstore, "
                +"brother, bad, bathroom, bedroom, bee, bike, "
                +"bread, breakfast, brown, bus, but, "
                +"cake, cat, color, cow, cup, can, chair, cloudy, "
                +"cold, cook, cool, cry, candy, car, card, "
                +"chicken, Chinese, classroom, clean, close, "
                +"coat, coffee, come, computer, cookie, "
                +"dad, dog, dance, day, desk, do(does), doctor, "
                +"door, draw, dinner, dirty, drink, drive, duck, "
                +"egg, ear, eight, eleven, eraser, eye, eat, "
                +"eighteen, eighty, elephant, e-mail, English, excited, "
                +"fish, face, fan, father, five, foot, four, Friday, "
                +"friend, fall, feel, fifteen, fifty, flower, fly, "
                +"food, for, forty, fourteen, from, fruit, "
                +"girl, green, grandma, grandpa, get, give, "
                +"glasses, go, good, gray(grey), "
                +"hamburger, hand, he, head, home, hot, how, "
                +"happy, hat, have/has, her, here, his, hospital, hundred, hungry, "
                +"I, it, ice cream, in, "
                +"juice, jump, jacket, Japan, "
                +"kid, key, kite, kitchen, "
                +"leg, like, lion, long, library, listen, live, living, "
                +"room, lunch, "
                +"mad, mom, my, many, marker, milk, "
                +"Monday, monkey, mother, mouth, much, "
                +"mailman, math, mine, moon, MRT, music, "
                +"name, nine, no, nose, now, not, nineteen, "
                +"ninety, noodles, nurse, "
                +"o'clock, old, on, one, orange, open, our, "
                +"pencil, pig, park, pen, pizza, pants, PE, pie, "
                +"pink, plane, play, police officer, police, "
                +"station, post office, purple, "
                +"red, rabbit, rainy, read, rice, ruler, restaurant, "
                +"ride, run, "
                +"Saturday, school, seven, she, short, sing, "
                +"sister, six, sleep, small, student, Sunday, "
                +"sunny, swim, sad, sandwich, say, science, "
                +"see, seventeen, seventy, ship, shoes, shop, "
                +"shorts, sick, singer, sit, sixteen, sixty, socks, "
                +"sofa, some, soup, speak, spring, star, study, "
                +"summer, sun, supermarket, sweater, sky, "
                +"that, this, table, tall, tea, teacher, ten, the, "
                +"these, they, thin, those, three, Thursday, tiger, "
                +"time, today, Tuesday, twelve, two, Taiwan, "
                +"take, talk, telephone, taxi, their, there, "
                +"thirteen, thirty, tired, train, tree, T-shirt, TV, "
                +"twenty, "
                +"under, uncle, USA, UK, "
                +"what, want, warm, water, we, weather, "
                +"Wednesday, week, where, white, who, "
                +"window, windy, write, walk, wash, watch, "
                +"wear, when, whose, winter, work, "
                +"yellow, you, yo-yo, year, yes, your, "
                +"zoo\n"


  let OpLimit3 = "I want you to act as an English teacher.\n" +
                 "Please use the following story :\n" + story + "\n" +
                 "optimize the story to comply with the following rules.\n" +
                 "1. The word complexity in the story must not be over " + level + ".\n" +
                 "2. The story must contain words list in the lexicon and Example sentence patterns.\n" +
                 "3. The story is for elementary school students whose native language is not english, the vocabulary you use must not be complex.\n" +
                 "4. Background and theme of story content : " + topic + ".\n" +
                 "5. The length of the story must be within " + words + " words. Please double-check before returning the result.\n"

  let preWord = "The lexicon are: "
  let exSentPattern_CEFR_PreA = "Example sentence patterns : "
              +"Are you ready? (Yes./No.) ; "
              +"Be quiet./Quiet (, please). ; "
              +"Circle the word \"fish.\" ; "
              +"Come here (, please). ; "
              +"Go back to your seat, (please). ; "
              +"Good job./Very good. ; "
              +"Good morning, Ms. Wang. / Good afternoon, Mr. Lee. ; "
              +"Goodbye./Bye. ; "
              +"Hi!/Hello! ; "
              +"How are you? ; "
              +"I'm fine. / I'm (not) O.K. ; "
              +"Listen!/Listen to me!/Listen carefully! ; "
              +"Look!/Look here! ; "
              +"Open/Close your book. ; "
              +"Point to the word “fish.” ; "
              +"Raise your hand (, please). / Put your hand down (, please). ; "
              +"Repeat./Repeat after me (, please). ; "
              +"Sit down (, please). ; "
              +"Stand up (, please). ; "
              +"Take out/Put away your book. / Take it out./Put it away. ; "
              +"Thank you. ; "
              +"You're welcome. ; "
              +"Any questions? ; "
              +"Come in (, please). ; "
              +"Don't talk/run/shout. ; "
              +"Excuse me. ; "
              +"Good idea. ; "
              +"(I'm O.K.) How about you? ; "
              +"It's your turn. ; "
              +"I'm sorry. ; "
              +"Let's go. ; "
              +"Let's read/count (together). ; "
              +"Line up (, please). ; "
              +"May I go to the bathroom/restroom? ; "
              +"Please turn to page 12. ; "
              +"Put down/Pick up your pencil. / Put it down./Pick it up. ; "
              +"See you./See you later. ; "
              +"Show me your homework (, please). ; "
              +"Sorry, I don't know. ; "
              +"Spell the word \"book.\" ; "
              +"Time's up. ; "
              +"Turn on/off the light (, please). ; "
              +"Which one (do you like)? ; "
              +"Who's next? ; "
              +"Write down the word/sentence (, please). ; "
              +"This is my book. ; "
              +"What color is it? It's green. ; "
              +"What's this?/What’s that?/What is it? It's a book/an apple/my pencil. ; "
              +"a. I am tall. b. He/She is tall  ; "
              +"a. What are these/those? They are tigers. b. Are these/those bears? Yes, they are./No, they aren't. ; "
              +"a. Are you a teacher? Yes, I am./No, I'm not. b. Is he/she a doctor? Yes, he/she is./No, he/she isn't. ; "
              +"a. Is it/this/that a banana/an apple? Yes, it is./No, it isn't. ; "
              +"a. Can you dance? Yes, I can./No, I can't. b. Can he/she dance? Yes, he/she can./No, he/she can't. ; "
              +"a. What are you doing (now)? I'm reading. b. What is he/she doing? He/She is singing. Who's he/she? He/She is my teacher. ; "
              +"a. How old are you? I am eight year(s) old. b. How old is he/she? He/She is ten year(s) old."
              +"a. Where is the pen? It's in/on/under the box. b. Where are the books? They're in/on/under the desk. ; "
              +"a. Where are you? I'm in the park/at school. b. Where is he/she? He/She is in the park/ at school. ; "
              +"a. What time is it? It's one (o'clock). b. Is it four (o'clock)? Yes, it is./No, it isn't. ; "
              +"a. What day is today? It's Sunday. b. Is it Monday today? Yes, it is./No, it isn't. ; "
              +"How's the weather? It's sunny. ; "
              +"a. How many pencils (are there)? (There are) three. b. How many balls do you want? I want two. ; "
              +"How much is it? It’s five dollars.\n"
  let exSentPattern_CEFR_A1 = "Example sentence patterns : "
                +"Are you ready? (Yes./No.) ; "
                +"Be quiet./Quiet (, please) ; "
                +"Circle the word \"fish. ; "
                +"Come here (, please). ; "
                +"Go back to your seat, (please). ; "
                +"Good job./Very good. ; "
                +"Good morning, Ms. Wang. / Good afternoon, Mr. Lee. ; "
                +"Goodbye./Bye. ; "
                +"Hi!/Hello! ; "
                +"How are you? ; "
                +"I'm fine. / I'm (not) O.K. ; "
                +"Listen!/Listen to me!/Listen carefully! ; "
                +"Look!/Look here! ; "
                +"Open/Close your book. ; "
                +"Point to the word “fish.” ; "
                +"Raise your hand (, please). / Put your hand down (, please). ; "
                +"Repeat./Repeat after me (, please). ; "
                +"Sit down (, please). ; "
                +"Stand up (, please). ; "
                +"Take out/Put away your book. / Take it out./Put it away. ; "
                +"Thank you. ; "
                +"You're welcome. ; "
                +"Any questions? ; "
                +"Come in (, please). ; "
                +"Don't talk/run/shout. ; "
                +"Excuse me. ; "
                +"Good idea. ; "
                +"(I'm O.K.) How about you? ; "
                +"It's your turn. ; "
                +"I'm sorry. ; "
                +"Let's go. ; "
                +"Let's read/count (together). ; "
                +"Line up (, please). ; "
                +"May I go to the bathroom/restroom? ; "
                +"Please turn to page 12. ; "
                +"Put down/Pick up your pencil. / Put it down./Pick it up. ; "
                +"See you./See you later. ; "
                +"Show me your homework (, please). ; "
                +"Sorry, I don't know. ; "
                +"Spell the word \"book.\" ; "
                +"Time's up. ; "
                +"Turn on/off the light (, please). ; "
                +"Which one (do you like)? ; "
                +"Who's next? ; "
                +"Write down the word/sentence (, please). ; "
                +"Are you done/finished? ; "
                +"Are you sure? ; "
                +"Give me a hand (, please). ; "
                +"Go on./Continue. ; "
                +"Have fun./Have a good time. ; "
                +"Hurry up (, please). ; "
                +"Is that all? ; "
                +"It's time for lunch. ; "
                +"Just a minute (, please). ; "
                +"Make a sentence (, please). ; "
                +"May I help you/borrow your book? ; "
                +"One more time (, please). ; "
                +"Share the book (with your friends). ; "
                +"Sorry, I’m late. ; "
                +"Stop it./Don’t do it again. ; "
                +"Take it easy. ; "
                +"That's all (for today). ; "
                +"That's okay/all right. ; "
                +"Try again (, please). ; "
                +"Turn left/right. ; "
                +"Watch out! ; "
                +"What happened?/What's the matter? ; "
                +"Work in pairs/groups. ; "
                +"This is my book. ; "
                +"What color is it? It's green. ; "
                +"What's this?/What’s that?/What is it? It's a book/an apple/my pencil. ; "
                +"a. I am tall. b. He/She is tall ; "
                +"a. What are these/those? They are tigers. b. Are these/those bears? Yes, they are./No, they aren't. ; "
                +"a. Are you a teacher? Yes, I am./No, I'm not. b. Is he/she a doctor? Yes, he/she is./No, he/she isn't. ; "
                +"a. Is it/this/that a banana/an apple? Yes, it is./No, it isn't. ; "
                +"a. Can you dance? Yes, I can./No, I can't. b. Can he/she dance? Yes, he/she can./No, he/she can't. ; "
                +"a. What are you doing (now)? I'm reading. b. What is he/she doing? He/She is singing. ; "
                +"Who's he/she? He/She is my teacher. ; "
                +"a. How old are you? I am eight year(s) old. b. How old is he/she? He/She is ten year(s) old. ; "
                +"a. Where is the pen? It's in/on/under the box. b. Where are the books? They're in/on/under the desk. ; "
                +"a. Where are you? I'm in the park/at school. b. Where is he/she? He/She is in the park/ at school. ; "
                +"a. What time is it? It's one (o'clock). b. Is it four (o'clock)? Yes, it is./No, it isn't. ; "
                +"a. What day is today? It's Sunday. b. Is it Monday today? Yes, it is./No, it isn't. ; "
                +"How's the weather? It's sunny. ; "
                +"a. How many pencils (are there)? (There are) three. b. How many balls do you want? I want two. ; "
                +"How much is it? It’s five dollars. ; "
                +"a. Are you happy? Yes, I am./No, I'm not. b. Is he/she tall? Yes, he/she is./No, he/she isn't. ; "
                +"a. Do you like apples? Yes, I do./No, I don't. b. Does he/she like bananas? Yes, he/she does./No, he/she doesn't. ; "
                +"a. What do you like? I like dogs. b. What does he/she like? He/She likes cats. ; "
                +"What would you like for breakfast? Juice and bread, please. ; "
                +"a. Do you have a pen/an eraser? Yes, I do./No, I don't. b. Does he/she have a ruler/an orange? Yes, he/she does./No, he/she doesn't. ; "
                +"How do you go to the park? I go to the park by bike/on foot. ; "
                +"How do you feel? I feel tired. ; "
                +"When/What time do you get up? I get up at six ; "
                +"a. Whose key is it? It's his (key). b. Whose shoes are they? They’re my shoes/mine. ; "
                +"a. What are you drawing? I'm drawing a plane. b. What is he/she drawing? He's/She's drawing a train. ; "
                +"a. Where are you from? I'm from Taiwan. b.Where is he/she from? He's/She's from Japan. ; "
                +"a. What do you do? I'm a nurse. b. What does he/she do? He's/She's a teacher ; \n"

  let keyWordList
  let exSentPattern
  if(level==LISTEN_READ_LEVEL.STARTER.CEFR){
    keyWordList=keyWordList_CEFR_PreA
    exSentPattern=exSentPattern_CEFR_PreA
  }
  else if(level==LISTEN_READ_LEVEL.BEGINNER.CEFR){
    keyWordList=keyWordList_CEFR_A1
    exSentPattern=exSentPattern_CEFR_A1
  }
  let sysSetup1 = preWord + keyWordList+exSentPattern +OpLimit3
  let goPrompt = "<|im_start|>user\n " + sysSetup1 + " <|im_end|>\n" + "<|im_start|>user\n" + "Please generate a "+words+"-word story, within this word limit" + "\n<|im_end|>\n<|im_start|>assistant"
                
  return await apiGenGhatGPT(goPrompt)
}

let keyWordList_CEFR_PreA  = "a(an), apple, arm, at, "
+"bag, be(am, are, is), ball, "
+"bird, blue, book, box, boy, "
+"banana, bear, big, black, "
+"bookstore, brother, "
+"cake, cat, color, cow, cup, "
+"can, chair, cloudy, cold, "
+"cook, cool, cry, "
+"dad, dog, dance, day, desk, "
+"do(does), doctor, door, draw, "
+"egg, ear, eight, eleven, "
+"eraser, eye, "
+"fish, face, fan, father, five, "
+"foot, four, Friday, friend, "
+"girl, green, grandma, grandpa, "
+"hamburger, hand, he, head, "
+"home, hot, how, "
+"I, it, ice cream, in, "
+"juice, jump, "
+"kid, key, kite, "
+"leg, like, lion, long, "
+"mom, my, many, marker, "
+"milk, Monday, monkey, "
+"mother, mouth, much, "
+"name, nine, no, nose, now, not, "
+"o'clock, old, on, one, orange, "
+"pencil, pig, park, pen, pizza, "
+"red, rabbit, rainy, read, "
+"rice, ruler, "
+"Saturday, school, seven, "
+"she, short, sing, sister, six, "
+"sleep, small, student, "
+"Sunday, sunny, swim, "
+"that, this, table, tall, tea, "
+"teacher, ten, the, these, "
+"they, thin, those, three, "
+"Thursday, tiger, time, "
+"today, Tuesday, twelve, two, "
+"under, "
+"what, want, warm, water, "
+"we, weather, Wednesday, "
+"week, where, white, who, "
+"window, windy, write, "
+"yellow, you, yo-yo, year, yes, "
+"zoo\n"
let keyWordList_CEFR_A1 = "a(an), apple, arm, at, and, angry, art, aunt, "
+"bag, be(am, are, is), ball, bird, blue, book, "
+"box, boy, banana, bear, big, black, bookstore, "
+"brother, bad, bathroom, bedroom, bee, bike, "
+"bread, breakfast, brown, bus, but, "
+"cake, cat, color, cow, cup, can, chair, cloudy, "
+"cold, cook, cool, cry, candy, car, card, "
+"chicken, Chinese, classroom, clean, close, "
+"coat, coffee, come, computer, cookie, "
+"dad, dog, dance, day, desk, do(does), doctor, "
+"door, draw, dinner, dirty, drink, drive, duck, "
+"egg, ear, eight, eleven, eraser, eye, eat, "
+"eighteen, eighty, elephant, e-mail, English, excited, "
+"fish, face, fan, father, five, foot, four, Friday, "
+"friend, fall, feel, fifteen, fifty, flower, fly, "
+"food, for, forty, fourteen, from, fruit, "
+"girl, green, grandma, grandpa, get, give, "
+"glasses, go, good, gray(grey), "
+"hamburger, hand, he, head, home, hot, how, "
+"happy, hat, have/has, her, here, his, hospital, hundred, hungry, "
+"I, it, ice cream, in, "
+"juice, jump, jacket, Japan, "
+"kid, key, kite, kitchen, "
+"leg, like, lion, long, library, listen, live, living, "
+"room, lunch, "
+"mad, mom, my, many, marker, milk, "
+"Monday, monkey, mother, mouth, much, "
+"mailman, math, mine, moon, MRT, music, "
+"name, nine, no, nose, now, not, nineteen, "
+"ninety, noodles, nurse, "
+"o'clock, old, on, one, orange, open, our, "
+"pencil, pig, park, pen, pizza, pants, PE, pie, "
+"pink, plane, play, police officer, police, "
+"station, post office, purple, "
+"red, rabbit, rainy, read, rice, ruler, restaurant, "
+"ride, run, "
+"Saturday, school, seven, she, short, sing, "
+"sister, six, sleep, small, student, Sunday, "
+"sunny, swim, sad, sandwich, say, science, "
+"see, seventeen, seventy, ship, shoes, shop, "
+"shorts, sick, singer, sit, sixteen, sixty, socks, "
+"sofa, some, soup, speak, spring, star, study, "
+"summer, sun, supermarket, sweater, sky, "
+"that, this, table, tall, tea, teacher, ten, the, "
+"these, they, thin, those, three, Thursday, tiger, "
+"time, today, Tuesday, twelve, two, Taiwan, "
+"take, talk, telephone, taxi, their, there, "
+"thirteen, thirty, tired, train, tree, T-shirt, TV, "
+"twenty, "
+"under, uncle, USA, UK, "
+"what, want, warm, water, we, weather, "
+"Wednesday, week, where, white, who, "
+"window, windy, write, walk, wash, watch, "
+"wear, when, whose, winter, work, "
+"yellow, you, yo-yo, year, yes, your, "
+"zoo\n"

export const listen_read_GPT=async (topic:String, level:String, words:Number)=>{
  let limit3 = "I want you to act as an English teacher. Your students are not native English speakers.\n" +
               "Please create a complete story in english within " + words + " words and follow the instruction below.\n" +
               "1. The word complexity in the story must not be over " + level + ".\n" +
               "2. Try to use some words list in the lexicon and Example sentence patterns.\n" +
               "3. The story is for elementary school students whose native language is not english, the vocabulary you use must not be complex.\n" +
               "4. Background and theme of story content : " + topic + ".\n" +
               "5. The length of the story must be within " + words + " words. Please double-check before returning the result.\n"

  let preWord = "The lexicon are: "
  let exSentPattern_CEFR_PreA = "Example sentence patterns : "
              +"Are you ready? (Yes./No.) ; "
              +"Be quiet./Quiet (, please). ; "
              +"Circle the word \"fish.\" ; "
              +"Come here (, please). ; "
              +"Go back to your seat, (please). ; "
              +"Good job./Very good. ; "
              +"Good morning, Ms. Wang. / Good afternoon, Mr. Lee. ; "
              +"Goodbye./Bye. ; "
              +"Hi!/Hello! ; "
              +"How are you? ; "
              +"I'm fine. / I'm (not) O.K. ; "
              +"Listen!/Listen to me!/Listen carefully! ; "
              +"Look!/Look here! ; "
              +"Open/Close your book. ; "
              +"Point to the word “fish.” ; "
              +"Raise your hand (, please). / Put your hand down (, please). ; "
              +"Repeat./Repeat after me (, please). ; "
              +"Sit down (, please). ; "
              +"Stand up (, please). ; "
              +"Take out/Put away your book. / Take it out./Put it away. ; "
              +"Thank you. ; "
              +"You're welcome. ; "
              +"Any questions? ; "
              +"Come in (, please). ; "
              +"Don't talk/run/shout. ; "
              +"Excuse me. ; "
              +"Good idea. ; "
              +"(I'm O.K.) How about you? ; "
              +"It's your turn. ; "
              +"I'm sorry. ; "
              +"Let's go. ; "
              +"Let's read/count (together). ; "
              +"Line up (, please). ; "
              +"May I go to the bathroom/restroom? ; "
              +"Please turn to page 12. ; "
              +"Put down/Pick up your pencil. / Put it down./Pick it up. ; "
              +"See you./See you later. ; "
              +"Show me your homework (, please). ; "
              +"Sorry, I don't know. ; "
              +"Spell the word \"book.\" ; "
              +"Time's up. ; "
              +"Turn on/off the light (, please). ; "
              +"Which one (do you like)? ; "
              +"Who's next? ; "
              +"Write down the word/sentence (, please). ; "
              +"This is my book. ; "
              +"What color is it? It's green. ; "
              +"What's this?/What’s that?/What is it? It's a book/an apple/my pencil. ; "
              +"a. I am tall. b. He/She is tall  ; "
              +"a. What are these/those? They are tigers. b. Are these/those bears? Yes, they are./No, they aren't. ; "
              +"a. Are you a teacher? Yes, I am./No, I'm not. b. Is he/she a doctor? Yes, he/she is./No, he/she isn't. ; "
              +"a. Is it/this/that a banana/an apple? Yes, it is./No, it isn't. ; "
              +"a. Can you dance? Yes, I can./No, I can't. b. Can he/she dance? Yes, he/she can./No, he/she can't. ; "
              +"a. What are you doing (now)? I'm reading. b. What is he/she doing? He/She is singing. Who's he/she? He/She is my teacher. ; "
              +"a. How old are you? I am eight year(s) old. b. How old is he/she? He/She is ten year(s) old."
              +"a. Where is the pen? It's in/on/under the box. b. Where are the books? They're in/on/under the desk. ; "
              +"a. Where are you? I'm in the park/at school. b. Where is he/she? He/She is in the park/ at school. ; "
              +"a. What time is it? It's one (o'clock). b. Is it four (o'clock)? Yes, it is./No, it isn't. ; "
              +"a. What day is today? It's Sunday. b. Is it Monday today? Yes, it is./No, it isn't. ; "
              +"How's the weather? It's sunny. ; "
              +"a. How many pencils (are there)? (There are) three. b. How many balls do you want? I want two. ; "
              +"How much is it? It’s five dollars.\n"
  let exSentPattern_CEFR_A1 = "Example sentence patterns : "
                +"Are you ready? (Yes./No.) ; "
                +"Be quiet./Quiet (, please) ; "
                +"Circle the word \"fish. ; "
                +"Come here (, please). ; "
                +"Go back to your seat, (please). ; "
                +"Good job./Very good. ; "
                +"Good morning, Ms. Wang. / Good afternoon, Mr. Lee. ; "
                +"Goodbye./Bye. ; "
                +"Hi!/Hello! ; "
                +"How are you? ; "
                +"I'm fine. / I'm (not) O.K. ; "
                +"Listen!/Listen to me!/Listen carefully! ; "
                +"Look!/Look here! ; "
                +"Open/Close your book. ; "
                +"Point to the word “fish.” ; "
                +"Raise your hand (, please). / Put your hand down (, please). ; "
                +"Repeat./Repeat after me (, please). ; "
                +"Sit down (, please). ; "
                +"Stand up (, please). ; "
                +"Take out/Put away your book. / Take it out./Put it away. ; "
                +"Thank you. ; "
                +"You're welcome. ; "
                +"Any questions? ; "
                +"Come in (, please). ; "
                +"Don't talk/run/shout. ; "
                +"Excuse me. ; "
                +"Good idea. ; "
                +"(I'm O.K.) How about you? ; "
                +"It's your turn. ; "
                +"I'm sorry. ; "
                +"Let's go. ; "
                +"Let's read/count (together). ; "
                +"Line up (, please). ; "
                +"May I go to the bathroom/restroom? ; "
                +"Please turn to page 12. ; "
                +"Put down/Pick up your pencil. / Put it down./Pick it up. ; "
                +"See you./See you later. ; "
                +"Show me your homework (, please). ; "
                +"Sorry, I don't know. ; "
                +"Spell the word \"book.\" ; "
                +"Time's up. ; "
                +"Turn on/off the light (, please). ; "
                +"Which one (do you like)? ; "
                +"Who's next? ; "
                +"Write down the word/sentence (, please). ; "
                +"Are you done/finished? ; "
                +"Are you sure? ; "
                +"Give me a hand (, please). ; "
                +"Go on./Continue. ; "
                +"Have fun./Have a good time. ; "
                +"Hurry up (, please). ; "
                +"Is that all? ; "
                +"It's time for lunch. ; "
                +"Just a minute (, please). ; "
                +"Make a sentence (, please). ; "
                +"May I help you/borrow your book? ; "
                +"One more time (, please). ; "
                +"Share the book (with your friends). ; "
                +"Sorry, I’m late. ; "
                +"Stop it./Don’t do it again. ; "
                +"Take it easy. ; "
                +"That's all (for today). ; "
                +"That's okay/all right. ; "
                +"Try again (, please). ; "
                +"Turn left/right. ; "
                +"Watch out! ; "
                +"What happened?/What's the matter? ; "
                +"Work in pairs/groups. ; "
                +"This is my book. ; "
                +"What color is it? It's green. ; "
                +"What's this?/What’s that?/What is it? It's a book/an apple/my pencil. ; "
                +"a. I am tall. b. He/She is tall ; "
                +"a. What are these/those? They are tigers. b. Are these/those bears? Yes, they are./No, they aren't. ; "
                +"a. Are you a teacher? Yes, I am./No, I'm not. b. Is he/she a doctor? Yes, he/she is./No, he/she isn't. ; "
                +"a. Is it/this/that a banana/an apple? Yes, it is./No, it isn't. ; "
                +"a. Can you dance? Yes, I can./No, I can't. b. Can he/she dance? Yes, he/she can./No, he/she can't. ; "
                +"a. What are you doing (now)? I'm reading. b. What is he/she doing? He/She is singing. ; "
                +"Who's he/she? He/She is my teacher. ; "
                +"a. How old are you? I am eight year(s) old. b. How old is he/she? He/She is ten year(s) old. ; "
                +"a. Where is the pen? It's in/on/under the box. b. Where are the books? They're in/on/under the desk. ; "
                +"a. Where are you? I'm in the park/at school. b. Where is he/she? He/She is in the park/ at school. ; "
                +"a. What time is it? It's one (o'clock). b. Is it four (o'clock)? Yes, it is./No, it isn't. ; "
                +"a. What day is today? It's Sunday. b. Is it Monday today? Yes, it is./No, it isn't. ; "
                +"How's the weather? It's sunny. ; "
                +"a. How many pencils (are there)? (There are) three. b. How many balls do you want? I want two. ; "
                +"How much is it? It’s five dollars. ; "
                +"a. Are you happy? Yes, I am./No, I'm not. b. Is he/she tall? Yes, he/she is./No, he/she isn't. ; "
                +"a. Do you like apples? Yes, I do./No, I don't. b. Does he/she like bananas? Yes, he/she does./No, he/she doesn't. ; "
                +"a. What do you like? I like dogs. b. What does he/she like? He/She likes cats. ; "
                +"What would you like for breakfast? Juice and bread, please. ; "
                +"a. Do you have a pen/an eraser? Yes, I do./No, I don't. b. Does he/she have a ruler/an orange? Yes, he/she does./No, he/she doesn't. ; "
                +"How do you go to the park? I go to the park by bike/on foot. ; "
                +"How do you feel? I feel tired. ; "
                +"When/What time do you get up? I get up at six ; "
                +"a. Whose key is it? It's his (key). b. Whose shoes are they? They’re my shoes/mine. ; "
                +"a. What are you drawing? I'm drawing a plane. b. What is he/she drawing? He's/She's drawing a train. ; "
                +"a. Where are you from? I'm from Taiwan. b.Where is he/she from? He's/She's from Japan. ; "
                +"a. What do you do? I'm a nurse. b. What does he/she do? He's/She's a teacher ; \n"
  let keyWordList
  let exSentPattern
  if(level==LISTEN_READ_LEVEL.STARTER.CEFR){
    keyWordList=keyWordList_CEFR_PreA
    exSentPattern=exSentPattern_CEFR_PreA
  }
  else if(level==LISTEN_READ_LEVEL.BEGINNER.CEFR){
    keyWordList=keyWordList_CEFR_A1
    exSentPattern=exSentPattern_CEFR_A1
  }
  let sysSetup1 = preWord + keyWordList+exSentPattern +limit3
  let goPrompt = "<|im_start|>user\n " + sysSetup1 + " <|im_end|>\n" + "<|im_start|>user\n" + "Please generate a "+words+"-word story, within this word limit" + "\n<|im_end|>\n<|im_start|>assistant"
                
  return await apiGenGhatGPT(goPrompt)
}

function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const listen_read_GPT_new=async (topic:String,level:any,words:number)=>{
  let level_criteria=0.85
  let rangeString='2. At least 80% ~ 90% of the words in the story are CEFR A1. The higher, the better.'
  let chooseWordList=keyWordList_CEFR_A1
  if (level==LISTEN_READ_LEVEL.STARTER){
    level_criteria=0.9
    rangeString='2. At least 90% of the words in the story are CEFR A1. The higher, the better.'
    chooseWordList=keyWordList_CEFR_PreA
  }
  let word_diff_criteria=10

  let setting={role:'system',content:"You are an elementary school English teacher. Your students are not native English speakers.\n"}
  
  let descriptionString=
    "Please create a complete story in English and strictly follow the conditions below.\n"
    +"1. It’s a listening comprehension context.\n"
    +rangeString+"\n"
    +"3. Use words from database below lexicon. At least 60% of the words in the story should come from the database I offered.\n"
    +"4. The topic and the subtopic of the story are " + topic+"\n"
    +"5. Only use present tense to tell the story.\n"
    +"6. The word count of the story must be between " + (words-10) + " - " + (words+10) + " words\n"
    +"the lexicon are :\n"+chooseWordList+"\n"
    +"No explanations or illustrations, just a simple and complete story\n"
  let user={role:'user',content:descriptionString}
  let prompt=[]
  prompt.push(setting)
  prompt.push(user)

  let diff=0
  let a1_criteria=0
  let retry=0
  let article
  do{
    // initial
    diff=0
    a1_criteria=0

    //generate article
    let result=await apiGenGhatGPT(prompt) as any
    article=result.data.choices[0].message.content

    //calculate criteria
    const {gradeData,removeList}=await calculateGrade(article)
    let currentWordCount=0
    for (let i of Object.values(gradeData)){
      currentWordCount+=i
    }
    diff=currentWordCount-words
    a1_criteria=gradeData.A1/currentWordCount
    let removeString=''
    for(let item of removeList){
      removeString+=item+","
    }

    retry++
    console.log("try:",retry,'a1_criteria',a1_criteria,'diff',diff)
    
    prompt=[]
    prompt.push(setting)
    if(a1_criteria<level_criteria){
      let content=descriptionString
        +"Must not use any of the words list below:\n"
        +removeString+"\n"
      let item={role:'user',content:content}
      prompt.push(item)
    }
    else if(Math.abs(diff)>10 && Math.abs(diff)<20){
      let content={role:'user',content:descriptionString}
      prompt.push(content)
      content={role:'assistant',content:article}
      prompt.push(content)
      if(diff>0){
        content={role:'user',content:`The article is too long. Remove 1-3 sentences from the article. The count of words of the article MUST be close to ${words}\n`}
      }
      else{
        content={role:'user',content:`The article is too short. Add 1-3 sentences into the article. The count of words of the article MUST be close to ${words}\n`}
      }
      prompt.push(content)
    }
    else if(diff>=20){
      let content={role:'user',content:descriptionString}
      prompt.push(content)
    }
    await sleep(1000)
  }while((Math.abs(diff)>word_diff_criteria || a1_criteria<level_criteria) && retry<10)
  return article
}
function countWords(s:string) {
  s = s.replace(/\.|\/|\\|,|:|;|\?|"|!|\n/g," ")//remove punctuation marks
  s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
  s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
  return s.split(' ').filter(function(str){return str!="";}).length;
}

export const gen_qa_pairs=async (story:String,quantity:number, cefr_level:String, Question_conditions:String)=>{
  //open qa
  let preword='The story are: '
  let limit= "";
  let candadiates=quantity*2

  if ("CEFR PreA" == cefr_level) {
    limit = limit + "I want you to act as an English teacher. Your students are elementary school first to fourth grade students whose native language is not English, therefore the word complexity should not be too hard.\n";
  } else {
    limit = limit + "I want you to act as an English teacher. Your students are elementary school fifth to sixth grade students whose native language is not English, therefore the word complexity should not be too hard.\n";
  }

  if(Question_conditions=='open-ended question'){ 
    limit = limit +"Please follow the instruction below and ask me " + candadiates + " open-ended questions according to the story in English, and also provide a reference answer for each question.\n"
                  +"1. The word complexity in questions should not be over CEFR A1.\n"
                  +"2. The question must be base on student's own feeling and question format must conform to one of the following.\n"
                      +"a. How do you feel about the story?\n"
                      +"b. What choice would you make if you were the main character?\n"
                      +"c. What do you learn after reading the story?\n"
                      +"d. What if ...?\n";

    if ("CEFR PreA" == cefr_level) {
      limit = limit +"3. The question is for elementary school first to fourth grade students whose native language is not English, the vocabulary you use should not be complex.\n";
    } else {
      limit = limit +"3. The question is for elementary school fifth to sixth grade students whose native language is not English, the vocabulary you use should not be complex.\n";
    }

    limit = limit +"4. Only give me reference answer and question. Both of the answer and question must be completed sentences with subject and verb.\n"
       +"5. The answer of the question must not be found in the story. \n"
       +"6. Answers should not be options or questions.\n"
       +"7. Each of the question should be within 20 words.\n"
       +'8. The result must be in the format of. [{"Q":"xxx","A":"xxx"},{"Q":"yyyy","A":"yyyy"}]\n'
       +"9. Please do not generate fill-in-the-blank answers.\n";//fix TT 1378448

  } else {

    limit = limit +"Please follow the instruction below and ask me " + candadiates + " close-ended questions according to the story in English, and also provide a reference answer for each question.\n";

    if ("CEFR PreA" == cefr_level) {
      limit = limit +"1. The question is for elementary school first to fourth grade students whose native language is not English, the vocabulary you use should not be complex.\n";
    } else {
      limit = limit +"1. The question is for elementary school fifth to sixth grade students whose native language is not English, the vocabulary you use should not be complex.\n";
    }

    limit = limit +"2. Only give correct answer and question. Both of the answer and question must be completed sentences.\n"
       +"3. The answer of the question must be found in the story. \n"
       +"4. Each of the question should be within 20 words.\n"
       +'5. The result must be in the format of. [{"Q":"xxx","A":"xxx"},{"Q":"yyyy","A":"yyyy"}]\n';

  }

  let goPrompt=[{role:'user',content:preword+story + "\n" + limit}]
  let result
  let jsonParseException=false
  let retry=0
  let needCandidates=[]
  do{
    jsonParseException=false
    needCandidates.length=0
    result=await apiGenGhatGPT(goPrompt) as any
    console.log(Question_conditions,'retry',retry++)
    let dataPart=result.data.choices[0].message.content
    try{
      let json=JSON.parse(dataPart)
      for(let item of json){
        if(countWords(item.Q)>30 || countWords(item.A)>30 
          || item.A.includes('?') || item.A.includes("_") || item.A.includes('...')
          || !isCompleteSentence(item.A)||!isCompleteSentence(item.Q)){
          // debug debug debug debug debug debug
          console.error(Question_conditions,'illegal error:',item)
          console.error(Question_conditions,'countwordsQ',countWords(item.Q)>30)
          console.error(Question_conditions,'countwordsA',countWords(item.A)>30)
          console.error(Question_conditions,'include?',item.A.includes('?'))
          console.error(Question_conditions,'include_',item.A.includes('_'))
          console.error(Question_conditions,'include...',item.A.includes('...'))
          console.error(Question_conditions,'not completeQ',!isCompleteSentence(item.Q))
          console.error(Question_conditions,'not completeA',!isCompleteSentence(item.A))
        }
        else{
          needCandidates.push(item)
        }
        if(needCandidates.length==quantity){
          break;
        }
      }
    }catch(err){
      jsonParseException=true
      console.error(Question_conditions,'json error:',err,dataPart)
    }
  }while((jsonParseException || (retry<10 && needCandidates.length<quantity)))
  console.log(Question_conditions,'Candidates',JSON.parse(result.data.choices[0].message.content))
  if(needCandidates.length<quantity){
    console.error(Question_conditions,'still have not enough quantity')
    let json=JSON.parse(result.data.choices[0].message.content)
    for(let item of json){
      if(!needCandidates.includes(item)){
        needCandidates.push(item)
        if(needCandidates.length==quantity){
          break;
        }
      }
    }
  }
  console.log(Question_conditions,'output',needCandidates)
  result.data.choices[0].text=JSON.stringify(needCandidates)
  return result 
}

export const translationGPT=(text:string)=>{
  let gptString=`Please help me translate "${text}" into English perfectly. Only return the translated string`
  let prompt= "<|im_start|>user\n " + gptString + " <|im_end|>\n" + "<|im_start|>user\n<|im_start|>assistant"
  return apiGenGhatGPT(prompt)
}

export const apiGenGhatGPT= (goPrompt:Object) => 
new Promise((resolve, reject)=>{
  var chatGPTParameter={
    model: "gpt35-turbo-test",  
    messages: goPrompt,
    temperature: 1.5,
    max_tokens: 800,
    top_p: 0.95,
    frequency_penalty: 0,
    presence_penalty: 0,
    stop: null
  }

  var headers={
    headers: {
        'api-key': 'c40403a02ae947cc96282babea814c67',
        'Content-Type': 'application/json'
    },
    timeout: 1000*15
  }

  axios.post('https://gpttest001.openai.azure.com/openai/deployments/gpt35-turbo-test/chat/completions?api-version=2024-08-01-preview'
                ,chatGPTParameter, headers)
  .then(
    response=>{
      resolve(response)
    }
  )
  .catch(
    error=>{
      reject(error)
    }
  )
})

export const apiAddCoursesTitle = (title: string) => {
  return instance.post('/courses', title);
};

export const apiDuplicateCourse = (courseId: string) => {
  return instance.post('/courses/duplicate', {course_id: courseId});
};

export const apiUpdateCoursesTitle = (courseId: string, title: string) => {
  return instance.patch('courses/' + courseId, {
    title: title
  });
};

export const apiDeleteCoursesTitle = (courseId: string) => {
  return instance.delete('courses/' + courseId);
};

export const apiGetUnits = (courseId: string) => {
  return instance.get('courses/' + courseId + '/units');
};

export const apiGetUnitsInformation = (courseId: string, unitId: string) => {
  return instance.get('courses/' + courseId + '/units/' + unitId + '/total_histories');
};

export const apiAddUnit = (courseId: string, title: string, type: string, index: number) => {
  return instance.post('courses/' + courseId + '/units', {title: title, type: type, index: index});
};

export const apiGetUnitContent = (courseId: string, unitId: string) => {
  return instance.get('courses/' + courseId + '/units/' + unitId + '/edit');
};

export const apiUpdateUnitContent = (courseId: string, unitId: string, data: any) => {
  return instance.patch('courses/' + courseId + '/units/' + unitId, data);
};

export const apiDeleteUnitContent = (courseId: string, unitId: string) => {
  return instance.delete('courses/'+courseId+'/units/'+unitId)
};

export const apiUpdateUnitTitle = (courseId: string, unitId: string, title: string) => {
  return instance.patch('courses/' + courseId + '/units/' + unitId + '/update_title', {
    title: title
  });
};

export const apiUnitMovePosition = (courseId: string, t_id: string, n_id: string) => {
  return instance.post('courses/' + courseId + '/units/move_position', {
    t_id: t_id,
    n_id: n_id
  })
}

export const apiImportUnit = (courseId: string, data: any) => {
  return instance.post('courses/'+courseId+'/import_units', data)
}


const timeoutTree = 60 * 30 * 1000;
// const timeoutTree = 10 * 1000;
export const apiUpdateTreeUnitContent = (courseId: string, unitId: string, data: any) => {
  console.log(JSON.stringify(data));
  return instance.post('courses/' + courseId + '/units/' + unitId + '/tree', data, {
    timeout: timeoutTree,
  });
};

export const apiTreeUnitStatus = (operationId: string) => {
  return instance.post(
    '/robots/operation_status',
    {operation_id: operationId},
    {
      timeout: timeoutTree,
    }
  );
};

export const apiGetStorageKey = () => {
  return instance.get('/storage_info');
};

export const apiGetGroupList = () => {
  return instance.get('groups')
};

export const apiGetGroupLearningJournal = (groupId: string) => {
  return instance.get('groups/' + groupId + '/learning_journal');
};

export const apiGetUserLearningJournal = (userId: string) => {
  return instance.get('users/' + userId + '/learning_journal'); //(SPEC 6.1.0)
};

// (SPEC 6.1.4, 6.1.5, 6.1.6)
export const apiGetUserUnitLearningJournal = (groupId: string, userId: string, unitId: string) => {
  return instance.get('groups/' + groupId + '/users/'+userId + '/learning_journal/units/' + unitId + '/history');
};

export const apiAssignCourseToGroup = (groupId: string, data: any) => {
  return instance.post('groups/' + groupId + '/assign_course', data);
};

export const apiRecycleCourseFromGroup = (groupId: string, courseId: string) => {
  return instance.post('groups/' + groupId + '/recycle_course', {'course_id': courseId});
};

export const apiAssignCourseToUser = (userId: string, data: any) => {
  return instance.post('users/' + userId + '/assign_course', data);
};

export const apiAssignCourseToUserDemo = (userId: string, data: any) => {
  return instance.post('users/' + userId + '/assign_course_unit', data);
};

export const apiRecycleCourseFromUser = (userId: string, courseId: string) => {
  return instance.post('users/' + userId + '/recycle_course', {'course_id': courseId});
};

export const apiClearAllAudios = (group_id: string, userId: string, courseId: string, unitId: string) => {
  return instance.delete('robots/remove_audios', {data: {'group_id': group_id, 'unit_id': unitId, 'course_id': courseId, 'user_id': userId}});
};

export const apiRemoveAudioFreetalk = (group_id: string, userId: string, courseId: string, unitId: string, subjectId: string, finishedAt: number) => {
  return instanceApi2.post('changeAudioStatus',  {'group_id': group_id, 'unit_id': unitId, 'course_id': courseId, 'user_id': userId, 'subject_id': subjectId, 'finished_at': finishedAt})
  // return instance.delete('robots/remove_audio', {data: {'group_id': group_id, 'unit_id': unitId, 'course_id': courseId, 'user_id': userId, 'subject_id': subjectId, 'finished_at': finishedAt}});
};

export const apiRemoveAudio = (group_id: string, userId: string, courseId: string, unitId: string, subjectId: string, finishedAt: number) => {
  return instance.delete('robots/remove_audio', {data: {'group_id': group_id, 'unit_id': unitId, 'course_id': courseId, 'user_id': userId, 'subject_id': subjectId, 'finished_at': finishedAt}});
};


export const apiGetCourseAssignmentInfo = (groupId: string, userId: string) => {
  return instance.post('courses/assignment_info', {
    'group_id': groupId,
    'user_id': userId
  })
}
