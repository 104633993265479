
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import {ThemeItem, NameSpace, MutationTypes} from '../store/type-defs'
import { Ref, Watch } from 'vue-property-decorator'
import {Route} from 'vue-router'
import {myEventBus, MyEventBusEvents } from '../setup/EventBus'
import { apiGetZlacAccountInfo } from '@/api/zlacAPI'
import { CourseModule } from '@/store/Course'
import {apiUpdateCoursesTitle} from '@/api/editAPI';
import {UnitModule} from '@/store/Unit';
import editTilteDialog from '../components/editTilteDialog.vue'
import router from '@/router'
import {log} from '@/utilities/logger'

@Component({
  components: {
    editTilteDialog
  }
})
export default class TopBar extends Vue{
  showAccountInfo = false;
  navigationIdx = 1;
  themeItems = [
        { idx: 1, title: '課程清單', icon: require('../assets/asus_alls_titleclass_n.svg'), bicon: require('../assets/asus_alls_titleclass_p.svg'), enable: true, path:'courseList',tid:'0' },
        { idx: 2, title: '學習管理', icon: require('../assets/asus_alls_titlelearn_n.svg'), bicon: require('../assets/asus_alls_titlelearn_p.svg'), enable: true, path:'groupList',tid:'1' },
        // { idx: 3, title: '通知管理', icon: require('../assets/asus_alls_titlenotice_n.svg'), bicon: require('../assets/asus_alls_titlenotice_p.svg'), enable: true, path:'notify',tid:'2' }
      ]
  pic = {icon: require('../assets/zenbo.png')};
  // isLogin = true;
  isShowSearchDiv = false;
  searchKeyword = "";
  isInChapter = false;
  isSearching = false;
  isInUnitList = false;
  nickname = '';
  email = '';
  role = null;
  groupAmount = 0;
  memberAmount = 0;
  showEditCourseDialog = false
  edit_course = 'edit_course'
  isShowSearchInput = false;
  isFocusInput = false;
  clientWidth = document.body.clientWidth;
  private searchCourseHint = '輸入課程名稱'
  private searchStudentHint = '輸入群組/學生名稱'
  private path = ''

  @Ref("search") readonly search!: HTMLInputElement

  @Watch('$route', {immediate: true, deep: true})
  onUrlChange(val: Route, oldVal: Route){
    if (val.name == 'courseList'){
      this.navigationIdx = 1;
    }else if(val.name == 'groupList' ||
        val.name == 'memberList' ||
        val.name == 'groupSearchResult' ||
        val.name == 'memberListHistory' ||
        val.name == 'memberHistory' ||
        val.name == 'unitHistory'){
      this.navigationIdx = 2;
    // }else if(val.path == '/notifymanagement'){
    //   this.navigationIdx = 3;
    }

    if (val.name === "unitList" ||
        val.name === "editUnit" ||
        val.name === "conveditor" ||
        val.name === "listen_read") {
      this.isInChapter = true;
    } else {
      this.isInChapter = false;
    }

    if (val.name === "unitList") {
      this.isInUnitList = true;
    }else {
      this.isInUnitList = false;
    }

    if (typeof this.$route?.query?.searchKeyword == "string") {
      this.searchKeyword = this.$route.query.searchKeyword;
      this.isSearching = true;
    } else {
      this.searchKeyword = "";
      this.isSearching = false;
    }

    if (val.name === "courseList" ||
        val.name === "groupList" ||
        val.name === "memberList" ||
        val.name === "groupSearchResult") {
      this.isShowSearchDiv = true;
    } else {
      this.isShowSearchDiv = false;
    }
  }

  get searchInputHint() {
    if (this.navigationIdx == 1) {
      return  this.searchCourseHint;
    } else if (this.navigationIdx == 2) {
      return this.searchStudentHint;
    }
  }

  created() {
    window.addEventListener("resize", this.changSearchInput);
  }

  destroyed() {
    window.addEventListener("resize", this.changSearchInput);
  }

  changSearchInput() {
    this.isShowSearchInput = (document.body.clientWidth > 1023)?true:false;
    this.clientWidth = document.body.clientWidth;
  }

  isLogin() {
    return this.getCookie('aticket').length > 0;
  }

  getCookie(cname: string) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  getSelectedClass (item: ThemeItem): string {
      if (item.idx === this.navigationIdx) {
        if (this.clientWidth > 1023) {
          return 'btn-selected'
        }else {
          return 'btn-selected_small_width'
        }
      } else{
        if (this.clientWidth > 1023) {
          return 'btn-unselected'
        }else {
          return 'btn-unselected_small_width'
        }
      }
    }

    doNavClickItem (item: ThemeItem) {
      this.isSearching = false;
      this.searchKeyword = '';
      if (item.enable && this.navigationIdx !== item.idx) {
        if ('/' + item.path !== this.$route.path && this.navigationIdx !== item.idx) { // prevent duplicated path push
          this.$router.push({ path: `/${item.path}` })
        }else{
          this.$router.push({ path: `/${item.path}` })
        }
      }

    }

    async toggleAccountInfo() {
      this.showAccountInfo = !this.showAccountInfo;
      if (this.showAccountInfo) {
        const response = await apiGetZlacAccountInfo();
        this.groupAmount = response.data.class;
        this.memberAmount = response.data.user;
        this.email = response.data.currentUser.email;
        this.nickname = response.data.currentUser.nickname;
        this.role = response.data.currentUser.role;
      }
    }

    getRole() {
      if (this.role === 0)
        return "管理者";
      else if (this.role === 1)
        return "老師";
      else
        return "";
    }
    short_hand (nickname: string) {
        let name = nickname;
        let firstName = ''
        let lastName = ''

        try {
          if(name.includes(' ')){
              const index = name.indexOf(' ')
              firstName = name.slice(0,index);
              lastName = name.slice(index+1);
          }else if(name.includes('_')){
              const index = name.indexOf('_')
              firstName = name.slice(0,index);
              lastName = name.slice(index+1);
          }else if(name.includes('.')){
              const index = name.indexOf('.')
              firstName = name.slice(0,index);
              lastName = name.slice(index+1);
          }else{
              firstName = name[0]
              lastName = name[1]
          }
          if(lastName == undefined)
          lastName = ' '

          let short_hand=firstName[0]+lastName[0];
          if (this.isPureChinese(short_hand)) {
            return firstName
          } else {
            return short_hand.toUpperCase()
          }
        } catch (error) {
          return ''
        }
    }

    gotoAccountManagementCenter() {
      if (window.location.hostname.includes("alls-dev.asus.com"))
        window.location.href="https://dev-account-edtech.asus.com/";
      else if (window.location.hostname.includes("alls-stage.asus.com"))
        window.location.href="https://stage-account-edtech.asus.com/";
      else if (window.location.hostname.includes("alls.asus.com") || window.location.hostname.includes("alls.southeastasia.cloudapp.azure.com") || window.location.hostname.includes("52.187.144.188"))
        window.location.href="https://account-edtech.asus.com/";
    }

    logout() {
      this.$cookies.remove("aticket", undefined, "asus.com");
      this.$store.dispatch("reset");
      this.showAccountInfo = false;
      this.$router.push({name: "Home", params:{logout: "true"}});
      // this.$store.commit(NameSpace.App + MutationTypes.App.LOGOUT, true)
    }

    focusSearchDiv() {
      this.isFocusInput = !this.isFocusInput;
    }

    blurfocusSearchDiv() {
      this.isFocusInput = false
      if (!this.searchKeyword && this.clientWidth < 1024) {
        this.isShowSearchInput = false;
      }
    }

    goSearch(){
      if (!this.isShowSearchInput) {
        this.isShowSearchInput = true;
        setTimeout(() => {
          this.search.focus();
        }, 10)
      }else {
        // this.$store.commit(NameSpace.Theme + MutationTypes.Theme.SEARCH, target)
        if (this.searchKeyword) {
          this.isSearching = true
          if (this.$route.path !== '/groupSearchResult') {
            this.path = this.$route.path
          }
          switch (this.path) {
            case '/courseList':
              this.$router.push({name: 'courseList', query: {searchKeyword: this.searchKeyword}});
              break;
            case '/groupList':
              this.$router.push({name: 'groupSearchResult', query: {searchKeyword: this.searchKeyword}, params:{name: "/groupList"}});
              break;
            case '/memberList':
              this.$router.push({name: 'groupSearchResult', query: {searchKeyword: this.searchKeyword}, params:{name: "/memberList"}});
              break;
          }
        }
      }
    }

    getCourseTitle():string {
      return CourseModule.title
    }

    showEditTitleDialog() {
      this.showEditCourseDialog = true
    }

    async editCourseDialogResult(update: boolean, name: string) {
      if (update && this.getCourseTitle() !== name) {
        var result = await apiUpdateCoursesTitle(CourseModule.id, name)
        this.$router.push({name:"unitList", params:{courseId: CourseModule.id, courseTitle: name}});
        CourseModule.setTitle(name)
        window.sessionStorage.setItem('courseName', name)
      }
      this.showEditCourseDialog = false
    }

    isPureChinese(input: string) {
      var reg = /^[\u4E00-\u9FA5]+$/
      if (reg.test(input)) {
        return true
      } else {
        return false
      }
    }
}
