// import { AppState, QAPair, ZENBO_JR, WordString, Translation, Chat, AnalyzeResult } from '../type-defs'
import {ThemesState} from '../type-defs'

export const state: ThemesState = {
  id: '0.0.0',
  sort: '',
  name: '課程清單',
  searchTarget: '',
  data: []
}

