







import TopBar from './components/TopBar.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: { TopBar }
})
export default class App extends Vue {
  isShowTopBar = true
  @Watch('$route')
  onRouteChanged(): void {
    if (this.$route.name === 'notfound') {
      this.isShowTopBar = false
    } else {
      this.isShowTopBar = true
    }
  }

  mounted(): void {
    if (this.$route.name !== undefined) {
      if (this.$route.name === 'notfound') {
        this.isShowTopBar = false
      } else {
        this.isShowTopBar = true
      }
    }
  }
}
