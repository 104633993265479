import { UserModule } from '@/store/User';
import axios from 'axios';
import { config } from 'vue/types/umd';
import { LoginAPI } from './loginAPI';

const getZlacApiURL = (url: string) => {
  if (url.includes("alls-dev.asus.com")) {
    return "https://zlac-dev.southeastasia.cloudapp.azure.com/api/";
  } else if (url.includes("alls-stage.asus.com")) {
    return "https://zlac-stage.southeastasia.cloudapp.azure.com/api/";
  } else if (url.includes("alls.asus.com") || url.includes("alls.southeastasia.cloudapp.azure.com") || url.includes("52.187.144.188")) {
    return "https://zlac.southeastasia.cloudapp.azure.com/api/";
  }
  return "https://zlac-dev.southeastasia.cloudapp.azure.com/api/";
};
export const getLogInURL = (url: string) => {
  if (url.includes("alls.asus.com") || url.includes("alls.southeastasia.cloudapp.azure.com") || url.includes("52.187.144.188")) {
    return "https://account.asus.com/signin.aspx";
  }else {
    return "https://dev-account.asus.com/signin.aspx";
  }
};
let page = ''
const instance = axios.create({baseURL: getZlacApiURL(window.location.hostname)});

instance.interceptors.request.use(
    config => {
        if(config.baseURL?.includes('signin'))
            return config;
        if(config.headers !== undefined) {
          config.headers['Authorization'] = UserModule.zlacToken;
          return config;
        }
    },
    error => {
        Promise.reject(error);
    }
)

instance.interceptors.response.use(
    config => {
      if(config.config.url?.includes('signin') && config.data.err_code === 11) {
        let address = window.location.href.split("#")
        if(address[1] !== "/") {
          window.location.href = "/";
        }
      }
      return config;
    },
    error => {
      if (error.config.__retryCount === undefined) {
        error.config.__retryCount = 0;
      }
      let status = -1;
      if (error.response) {
        if (error.response.status) {
          status = error.response.status;
        }
      }
//      const ticket = getCookie('aticket');
//      if (ticket === '') {
//        window.location.href = getLogInURL(window.location.hostname)
//        return;
//      }
//      if (error.config.url.includes('signin') && status == 401) {
//        LoginAPI.cleanCookie();
//        window.location.href = getLogInURL(window.location.hostname)
//        return;
//      }
      if (error.config.url.includes('signin') && (status == 400 || status == 401)) {
        if(page === 'HOME'){
          return error.response;
        }else {
          window.location.href = "/";
        }
      }
      if (error.config.__retryCount < 3) {
        error.config.__retryCount = error.config.__retryCount + 1;
        return new Promise(async (resolve, reject) => {
          const response = await apiGetZlacToken();
          UserModule.setZlacToken(response.data.Token);
          resolve(instance(error.config));
        });
      }
      return Promise.reject(error);
    }
  );

function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const apiGetZlacToken = (tmp: any = '') => {
  page = tmp
  const ticket = getCookie('aticket');
  return instance.post('/alls/users/signin', {aticket: ticket});
};

export const apiGetZlacAccountInfo = () => {
  return instance.get('/alls/users/ccexplorer', {headers: {Authorization: UserModule.zlacToken}});
}

export const apiGetPrivacyAllowlog = () => {
  return instance.get('/alls/privacy/allowlog', {headers: {Authorization: UserModule.zlacToken}});
}
